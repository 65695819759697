import React, { useState } from "react"
import styles from "../ContactForm/contact-form"
import classNames from "classnames/bind"
import ContactForm from "../ContactForm"
import LoginModal from "../LoginModal"
import { useMutation } from "@apollo/client"
import { ArtistDetailQuery, UpdateSavedArtist } from "../../queries/artists.js"

const cx = classNames.bind(styles)

// MARK: Move component tree to nextgen
const ArtistDetailSubheaderActions = (props) => {
  const [mutate] = useMutation(UpdateSavedArtist)
  const [loginModalTitle, setLoginModalTitle] = useState("Log in to Save Artists")

  const handleToggleSaveArtist = (e) => {
    const { userId, slug } = props

    e.preventDefault()

    if (!userId) {
      setLoginModalTitle("Log in to Save Artists")
      props.toggleLoginModalOpen()
      return false
    }

    mutate({
      variables: { userId, slug },
      refetchQueries: [
        {
          query: ArtistDetailQuery,
          variables: {
            slug,
            userId,
          },
        },
      ],
    })
    e.target.blur()
  }

  const handleToggleContactArtist = (e) => {
    e.preventDefault()

    if (!props.userId) {
      setLoginModalTitle("Log in to Contact Artists")
      props.toggleLoginModalOpen()
      return false
    }

    props.toggleOpen()
  }

  return (
    <div className={cx("container")}>
      <button
        className={cx(["btn", `${props.savedByUser ? "btn-gray" : "btn-white"}`])}
        onClick={handleToggleSaveArtist}
      >
        {props.savedByUser ? "Saved" : "Save"}
      </button>
      <button className={cx(["btn", "btn-dark-gray", "open-form"])} onClick={handleToggleContactArtist}>
        Contact Artist
      </button>
      {props.open && <ContactForm {...props} asGuest={!props.userId} />}
      {props.openLoginModal && <LoginModal {...props} message={loginModalTitle} />}
    </div>
  )
}

export default ArtistDetailSubheaderActions
